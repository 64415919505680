
import GButtonV2 from '../g-button-v2';
import GStars from '../g-stars';
import GBreadcrumb from '../g-breadcrumb';
import parseLinkHref from '~/utils/parseLinkHref';
import stats from '~/lib/storyblok-local-json/storyblok-stats';
import GLazyImage from '../g-lazy-image';
import GWaveCurlRepeat from '../g-wave-curl-repeat';
import GBlogRawContent from '~/components/goose/g-blog-raw-content/g-blog-raw-content.vue';

export default {
  name: 'g-header',
  components: {
    GBlogRawContent,
    GButtonV2,
    GStars,
    CapterraColored: () => import('assets/svg/icons/capterra-logo-colored.svg'),
    CapterraWhite: () => import('assets/svg/icons/capterra-logo-white.svg'),
    GBreadcrumb,
    GLazyImage,
    GWaveCurlRepeat,
    SquiggleShapeOne: () => import('assets/svg/squiggle/shape-01.svg'),
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subline: {
      type: String,
      default: '',
    },
    slug: {
      type: Object,
      default: null,
    },
    image: {
      type: Object,
      required: false,
      default: null,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    /**
     * the squiggles require a `postion: relative;` in their parent element
     * this is to ensure they are placed correctly in cms-header with an active g-spacer
     * this can be simplified when we merge goose the nuxt app
     */
    leftSquiggle: {
      type: Boolean,
      default: false,
    },
    bottomSquiggle: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    showEmbedForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalTargetMappings: {
        signup: '#signupModal',
        consultation: '#bookADemo',
      },
      capterra: {
        score: Number(stats.capterra_score),
      },
    };
  },
  computed: {
    ctaTerms() {
      return (
        this.header.signup_terms || this.$store.state.terms.global_signup_terms
      );
    },
    headerTag() {
      return this.header.title_tag || 'h2';
    },
    headerSize() {
      return this.header.title_size || 'type-h3';
    },
    sublineTag() {
      return this.header.subline_tag || 'h3';
    },
    sublineSize() {
      return this.header.subline_size || 'type-subheadline';
    },
    headlineClass() {
      return `${this.headerSize} g-header__headline text-${this.headingColor}${this.showSpark}`;
    },
    tagLineColor() {
      const color = {
        white: 'pink-40',
        yellow: 'pink-50',
        peach: 'pink-50',
        azure: 'peach-30',
        pink: 'peach-30',
        purple: 'peach-30',
        enterprise: 'peach-20',
      };
      return color[this.theme];
    },
    headingColor() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'azure-60'
        : 'grey-10';
    },
    textColor() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'grey-60'
        : 'grey-10';
    },
    capterraLogo() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'capterra-colored'
        : 'capterra-white';
    },
    squiggleColor() {
      if (this.theme === 'purple') {
        return 'purple-30';
      }
      if (this.theme === 'enterprise') {
        return 'peach-30';
      }
      return 'peach-40';
    },
    headerClass() {
      return `g-header--${this.header.title_style}`;
    },
    showSpark() {
      if (
        this.header.hasOwnProperty('brand_logo_alignment') &&
        typeof this.header.brand_logo_alignment !== 'undefined'
      ) {
        return this.header.brand_logo_alignment
          ? `spark spark--${this.header.brand_logo_alignment}`
          : '';
      }
      return '';
    },
    tagIsEnable() {
      return this.tags.length > 0;
    },
  },
  methods: {
    getModalTarget(buttonType) {
      if (typeof this.modalTargetMappings[buttonType] !== 'undefined') {
        return this.modalTargetMappings[buttonType];
      }
      return '';
    },
    parseLinkHref,
  },
};
