
import Intersect from '~/utils/intersect';

export default {
  name: 'g-lazy-asset',
  components: {
    Intersect,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'png',
    },
    alt: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    linkTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      src: '',
      baseUrl: 'https://www.deputy.com/g-assets/',
    };
  },
  computed: {
    isSVG() {
      return this.type === 'svg';
    },
    filetype() {
      return `.${this.type}`;
    },
    assetSrcset() {
      const x1 = `${this.baseUrl}${this.path}${this.filetype}, `;
      const x2 = `${this.baseUrl}${this.path}@2x${this.filetype} 2x,`;
      const x3 = `${this.baseUrl}${this.path}@3x${this.filetype} 3x`;
      return `${x1}${x2}${x3}`;
    },
    computedSrc() {
      return `${this.baseUrl}${this.path}${this.filetype}`;
    },
  },
  methods: {
    loadImage() {
      this.src = this.computedSrc;
    },
  },
};
