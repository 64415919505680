
import GSpacer from '../g-spacer';
import GButtonV2 from '../g-button-v2';
import GContainer from '../g-container';
import richtext from '~/utils/richtext';
import slider from 'mixins/slider';
import parseLinkHref from '~/utils/parseLinkHref';
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-header-with-carousel-quotes',
  components: {
    GSpacer,
    GButtonV2,
    GContainer,
    GLazyImage,
  },
  mixins: [slider],
  props: {
    blok: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
      validator: theme => ['light', 'dark'].includes(theme),
    },
  },
  data() {
    return {
      timerInterval: 3000,
      autoplay: true,
      offset: 0,
    };
  },
  created() {
    this.offset = this.$isDesktop ? 20 : 0;
  },
  methods: {
    customerImage(slide) {
      if (typeof slide.customer_image !== 'undefined') {
        if (slide.customer_image.imagePublicUrl !== '') {
          return slide.customer_image;
        } else {
          return slide.image;
        }
      }
      return slide.image;
    },
    generateAuthor(content) {
      return `${content.author}, ${content.author_title}, ${content.company}`;
    },
    paragraph(p) {
      if (typeof p !== 'undefined') {
        return richtext(JSON.parse(JSON.stringify(p)));
      }
      return '';
    },
    parseLinkHref,
  },
};
