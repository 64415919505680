
export default {
  name: 'g-friendly-id',
  filters: {
    slugify: id => {
      return id
        .replace(/^\s+|\s+$/g, '')
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
    },
  },
  props: {
    friendlyId: {
      type: String,
      required: true,
    },
  },
};
