
import GHighlight from '../g-highlight';
import GBreadcrumb from '../g-breadcrumb';
import GChecklist from '../g-checklist';
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';
import GButtonStore from '../g-button-store';
import GStars from '../g-stars';
import GMedia from '../g-media';
import GTypeWriter from '../g-type-writer';
import richtext, { removeEmptyTags, richtextType } from '~/utils/richtext';
import { checkIfDeputyCustomer } from '~/utils/analytics';

const __default__ = {
  name: 'g-header-media-ctas',
  components: {
    GHighlight,
    GBreadcrumb,
    GChecklist,
    GLazyImage,
    GButtonV2,
    GButtonStore,
    GSignupForm: () => import('../g-signup-form'),
    GStars,
    GMedia,
    GTypeWriter,
    IconArrowCurveUp: () => import('assets/svg/icons/icon-arrow-curve-up.svg'),
  },
  props: {
    breadcrumbs: {
      type: Boolean,
      default: false,
    },
    headerTag: {
      type: String,
      default: 'h1',
    },
    headerSize: {
      type: String,
      default: 'type-h2',
    },
    headline: {
      type: richtextType,
      required: true,
    },
    paragraph: {
      type: richtextType,
      default: null,
    },
    bullets: {
      type: Array,
      default: () => {
        return [];
      },
    },
    typeWriter: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showForm: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: String,
      default: 'g-header-media-ctas-form',
    },
    ctas: {
      type: Array,
      default: () => {
        return [];
      },
    },
    signupTerms: {
      type: String,
      required: false,
      default: '',
    },
    hideCtaTerms: {
      type: Boolean,
      default: false,
    },
    showStores: {
      type: Boolean,
      default: false,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    badges: {
      type: Array,
      default: null,
    },
    video: {
      type: Array,
      default: () => {
        return [];
      },
    },
    image: {
      type: Object,
      default: null,
    },
    lottie: {
      type: Array,
      default: () => {
        return [];
      },
    },
    referralHeadline: {
      type: String,
      default: '',
    },
    showTermsArrow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useReferralHeadline: !!this.referralHeadline,
      isMounted: false,
      imagePaddingBottomMobile: this.typeWriter[0] ? '20px' : null,
      isCustomer: false,
    };
  },
  computed: {
    hideCTA() {
      return this.isCustomer;
    },
    ctaTerms() {
      return this.signupTerms || this.$store.state.terms.global_signup_terms;
    },
    headerHTMLTag() {
      return this.headerTag || 'h1';
    },
    headlineClass() {
      return this.typeWriter[0]
        ? `type-h0 text-${this.headingColor}`
        : `${this.headerSize ?? 'type-h2'} text-${this.headingColor} mb-20`;
    },
    subheadlineClass() {
      return this.typeWriter[0] ? 'typeWriter-subheadline' : null;
    },
    headingColor() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'azure-60'
        : 'grey-10';
    },
    textColor() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'grey-60'
        : 'grey-10';
    },
    cleanParagraph() {
      return this.cleanRichtext(this.paragraph);
    },
    mediaProps() {
      return {
        image: this.image,
        'load-immediately': true,
        'animation-url': this.lottie[0]?.animation?.filename,
        'mobile-animation-url': this.lottie[0]?.mobileAnimation?.filename,
        'placeholder-image': this.lottie[0]?.placeholder,
        'video-embed-url': this.video[0]?.videoEmbedUrl,
        'video-thumbnail': this.video[0]?.videoThumbnail,
        'play-video-option': this.video[0]?.playVideoOption,
        'image-size-sm': 366,
        'image-size-md': 719,
        'image-size-lg': 927,
        'image-size-xl': 592,
      };
    },
    dynamicSpacingClass() {
      if (this.bullets.length >= 5) {
        return 'mb-lg-20';
      }
      return '';
    },
  },
  mounted() {
    this.isMounted = true;
    this.isCustomer = checkIfDeputyCustomer();
  },
  methods: {
    richtext,
    cleanRichtext(richtext) {
      return removeEmptyTags(this.richtext(richtext, true));
    },
    referralHeadlineWithUserDetails() {
      if (this.isMounted) {
        const dpUserDetails = window.location.search.match(
          /(dpUserDetails=)([a-zA-Z0-9]*)/g
        );
        if (dpUserDetails) {
          const userDetail = dpUserDetails[0].split('=')[1];
          return `${JSON.parse(atob(userDetail)).FirstName} ${
            this.referralHeadline
          }`;
        }
        this.useReferralHeadline = false;
        return '';
      }
      return '';
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "10556e18": (_vm.imagePaddingBottomMobile)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__