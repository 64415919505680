
import GSpacer from '../g-spacer';
import GFriendlyId from '../g-friendly-id';
import GAccordion from '../g-accordion';
import GLazyImage from '../g-lazy-image';
import GStatsQuote from '../g-stats-quote';

export default {
  name: 'g-feature-bucket',
  components: {
    GFriendlyId,
    GSpacer,
    GAccordion,
    GLazyImage,
    GStatsQuote,
  },
  props: {
    bucket: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    updateIndex(index) {
      this.current = index;
    },
  },
};
