
export default {
  name: 'g-dropdown-item',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$parent.$emit('item-selected', {
        value: this.value,
        text: this.$slots.default[0].text,
      });
    },
  },
};
