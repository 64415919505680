
import GLazyImage from '../g-lazy-image';
export default {
  name: 'g-exit-pop-up-blog',
  components: {
    GLazyImage,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      default: null,
    },
    tag: {
      type: String,
      required: true,
    },
  },
};
