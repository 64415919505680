import { render, staticRenderFns } from "./g-law-state-heading.vue?vue&type=template&id=6e15bb0a&scoped=true&"
import script from "./g-law-state-heading.vue?vue&type=script&lang=js&"
export * from "./g-law-state-heading.vue?vue&type=script&lang=js&"
import style0 from "./g-law-state-heading.vue?vue&type=style&index=0&id=6e15bb0a&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e15bb0a",
  null
  
)

export default component.exports