
import GMedia from '../g-media';

export default {
  name: 'g-law-state-heading',
  components: {
    GMedia,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tagline: {
      type: String,
      required: false,
    },
    icon: {
      type: Object,
      required: true,
    },
  },
};
