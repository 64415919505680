
import slider from '~/mixins/slider';
import GContainer from '../g-container';
import G2ColsImgCopy from '../g-2-cols-img-copy';
import GWaveContainer from '../g-wave-container';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';
import GHeader from '../g-header';

export default {
  name: 'g-full-width-carousel',
  components: {
    GContainer,
    G2ColsImgCopy,
    GWaveContainer,
    GHeader,
    Arrow: () => import('assets/svg/icons/icon-arrow-smal.svg'),
  },
  mixins: [slider],
  props: {
    carousels: {
      type: Array,
      default: () => [],
    },
    headerProps: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      autoplay: false,
      offset: 0,
      slidesDisplayed: 1,
    };
  },
  created() {
    // no sliding movement when we have just one slide
    if (this.carousels.length <= 1) {
      this.autoplay = false;
    }
  },
  methods: {
    getParagraph(carousel) {
      if (typeof carousel.paragraph !== 'undefined') {
        return richtext(carousel.paragraph, false, true);
      }
      return undefined;
    },
    getParseLink(carousel) {
      if (typeof carousel.link_url !== 'undefined') {
        return parseLinkHref(carousel.link_url, true, { linkName: 'linkUrl' });
      }
      return undefined;
    },
    getRichtextHeadline(carousel) {
      return carousel.richtext_headline
        ? richtext(carousel.richtext_headline)
        : null;
    },
  },
};
