
import GBreadcrumb from '../g-breadcrumb';
import GButtonV2 from '../g-button-v2';
import GContainer from '../g-container';
import modalControl from 'mixins/modalControl';
import { THEME } from '~/plugins/componentTheme';

const __default__ = {
  name: 'g-header-media-highlight',
  components: {
    GBreadcrumb,
    iconVectorArrow: () => import('assets/svg/icons/icon-vector-arrow.svg'),
    GButtonV2,
    GContainer,
  },
  mixins: [modalControl],
  props: {
    slug: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    videoThumbnail: {
      type: String,
      default: '',
    },
    videoEmbedUrl: {
      type: String,
      default: '',
    },
    primaryButtonText: {
      type: String,
      default: '',
    },
    primaryButtonTarget: {
      type: String,
      required: false,
      default: 'link',
    },
    primaryButtonLink: {
      type: String,
      default: '',
    },
    secondaryButtonText: {
      type: String,
      default: '',
    },
    secondaryButtonTarget: {
      type: String,
      required: false,
      default: 'link',
    },
    secondaryButtonLink: {
      type: String,
      default: '',
    },
    signupCtaId: {
      type: String,
      default: '',
    },
    tagText: {
      type: String,
      default: '',
    },
    tagLink: {
      type: String,
      default: '',
    },
    showBreadcrumb: {
      type: Boolean,
      default: false,
    },
    headerTag: {
      type: String,
      default: 'h1',
    },
    headerSize: {
      type: String,
      default: 'type-h1',
    },
  },
  computed: {
    getImage() {
      let imageUrl = '';
      if (this.image) {
        imageUrl = this.image;
      }
      if (this.videoThumbnail) {
        imageUrl = this.videoThumbnail;
      }
      return {
        '--background-image': `url(${imageUrl})`,
      };
    },
    iframeSrc() {
      return this.isShown ? this.videoEmbedUrl : '';
    },
    themeClass() {
      return this.theme ? `${this.theme.toLowerCase()}-theme` : '';
    },
    handWrittenColor() {
      const handWrittenColorMap = {
        [THEME.WHITE]: 'purple-50',
        [THEME.YELLOW]: 'purple-50',
        [THEME.PEACH]: 'purple-50',
        [THEME.AZURE]: 'yellow-40',
        [THEME.PINK]: 'yellow-40',
        [THEME.PURPLE]: 'yellow-40',
        [THEME.ENTERPRISE]: 'yellow-40',
      };

      return `rgb(var(--${
        handWrittenColorMap[this.theme.toLowerCase()] ?? this.textThemeColor
      }))`;
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.$nextTick(() => {
        this.$refs['heading']
          .querySelectorAll('.type-highlight-word--yellow')
          .forEach(el => el.classList.add('active'));
      });
      this.$nextTick(() => {
        this.$refs['heading']
          .querySelectorAll('.type-highlight-word')
          .forEach(el => el.classList.add('active'));
      });
    }, 500);
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "21f58fa4": (_vm.handWrittenColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__