
import GButtonV2 from '../g-button-v2';
import GLazyImage from '../g-lazy-image';
import GLottieAnimation from '../g-lottie-animation';

export default {
  name: 'g-illustration-copy-link',
  components: {
    GLazyImage,
    GButtonV2,
    GLottieAnimation,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      default: null,
    },
    animationUrl: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonHref: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile: true,
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth >= 768) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
