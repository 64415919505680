
import GChecklist from '../g-checklist';
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';
import GVideo from '../g-video/g-video';

export default {
  name: 'g-feature-header',
  components: {
    GVideo,
    GChecklist,
    GLazyImage,
    GButtonV2,
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ctaTerms() {
      return (
        this.header.signup_terms || this.$store.state.terms.global_signup_terms
      );
    },
  },
};
