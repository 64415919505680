
export default {
  name: 'g-lazy-image',
  props: {
    imageData: {
      type: Object,
      required: true,
    },
    loadImmediately: {
      type: Boolean,
      default: false,
    },
    // only use width/height for static sized images like icons or avatars
    // use source width/height for responsive images with sizes
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    imgSizeSm: {
      type: Number,
      default: null,
    },
    imgSizeMd: {
      type: Number,
      default: null,
    },
    imgSizeLg: {
      type: Number,
      default: null,
    },
    imgSizeXl: {
      type: Number,
      default: null,
    },
    imgSizeXxl: {
      type: Number,
      default: null,
    },
    // if static it will create srcset for 1x, 2x & 3x DPR based on width prop
    // else it will create srcset and sizes based on imgSize props
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setStyles() {
      let styles = `aspect-ratio: ${this.imageData.sourceImageWidth}/${this.imageData.sourceImageHeight}; `;
      if (this.isStatic) {
        styles += `min-width:${this.width}px;`;
      }
      return styles;
    },
    alt() {
      return this.imageData?.imageAlt || null;
    },
    src() {
      return `https://a.storyblok.com/${this.imageData.imageS3Key}`;
    },
    finalSrcsetAndSizes() {
      const result = { srcset: null, sizes: null };

      // we don't want to add any srcset to svgs beacuse
      // SB will convert the filetype, causing css and data size issues
      if (this.imageData.imagePublicUrl.endsWith('.svg')) {
        return result;
      }

      if (this.isStatic) {
        result.srcset = this.staticSrcset();
        return result;
      }

      return this.dynamicSrcsetAndSizes();
    },
    finalWidth() {
      return this.isStatic
        ? this.width
        : this.imageData?.sourceImageWidth || null;
    },
    finalHeight() {
      if (this.isStatic && this.height) {
        return this.height;
      }
      return this.imageData?.sourceImageHeight || null;
    },
  },
  methods: {
    staticSrcset() {
      const w = this.width || 0;
      const DPRs = [1, 2, 3];

      return DPRs.map(DPR => this.generateStaticSrc(w, DPR)).join(', ');
    },
    generateStaticSrc(w, DPR) {
      const width = this.maxWidth(w * DPR);

      return `${this.src}/m/${width}x0 ${DPR}x`;
    },
    // ensures we don't ask SB to resize an image bigger than the original, increasing data size
    maxWidth(width) {
      if (width === 0 || width === null) {
        return 0;
      }

      return Math.min(width, this.imageData.sourceImageWidth || Infinity);
    },
    dynamicSrcsetAndSizes() {
      const rawSizes = {
        sm: {
          screenSize: 414,
          imageSize: this.imgSizeSm,
        },
        md: {
          screenSize: 767,
          imageSize: this.imgSizeMd,
        },
        lg: {
          screenSize: 991,
          imageSize: this.imgSizeLg,
        },
        xl: {
          screenSize: 3000,
          imageSize: this.imgSizeXl,
        },
        xxl: {
          screenSize: 5500,
          imageSize: this.imgSizeXxl,
        },
      };

      const sizes = [];
      const srcset = [];
      let maxSize = 0;

      Object.keys(rawSizes).forEach(size => {
        const { screenSize, imageSize } = rawSizes[size];

        if (imageSize) {
          sizes.push(`(max-width: ${screenSize}px) ${imageSize}px`);
          srcset.push(this.generateDynamicSrc(imageSize));

          maxSize = Math.max(maxSize, imageSize);
        }
      });

      sizes.push('100vw');
      const maxRetinaWidth = (maxSize || this.imageData?.sourceImageWidth) * 2;
      srcset.push(this.generateDynamicSrc(maxRetinaWidth));

      return {
        sizes: sizes.join(', '),
        srcset: srcset.join(', '),
      };
    },
    generateDynamicSrc(w) {
      const width = this.maxWidth(w);

      return `${this.src}/m/${width}x0 ${width}w`;
    },
  },
  head() {
    const srcset = this.finalSrcsetAndSizes.srcset;
    const sizes = this.finalSrcsetAndSizes.sizes;
    if (this.loadImmediately && srcset) {
      const link = [
        {
          rel: 'preload',
          as: 'image',
          imagesrcset: srcset,
          fetchpriority: 'high',
        },
      ];

      if (sizes) {
        link[0]['imagesizes'] = sizes;
      }
      return {
        link,
      };
    }
  },
};
