
import GLazyImage from '../g-lazy-image';
import GStatsQuote from '../g-stats-quote';
import GButtonV2 from '../g-button-v2';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'g-feature-highlight',
  components: {
    GLazyImage,
    GStatsQuote,
    GButtonV2,
  },
  props: {
    feature: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isImageLeftAligned() {
      if (typeof this.feature !== 'undefined') {
        return this.feature.image_alignment === 'left';
      }
      return false;
    },
  },
  methods: {
    paragraph(p) {
      if (typeof p !== 'undefined') {
        return richtext(p);
      }
      return '';
    },
    parseLinkHref,
  },
};
