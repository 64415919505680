
import richtext from '~/utils/richtext';
import GToggleIcon from '../g-toggle-icon';

export default {
  name: 'g-faq-item',
  components: {
    GToggleIcon,
  },
  props: {
    faq: {
      type: Object,
      required: true,
    },
    anchored: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isClosed: true,
      id: null,
    };
  },
  computed: {
    answer() {
      if (typeof this.faq.answer !== 'undefined') {
        return typeof this.faq.answer === 'object'
          ? richtext(this.faq.answer)
          : this.faq.answer;
      }
      return '';
    },
  },
  watch: {
    anchored: function (val) {
      // this watcher is used for anchor link clicked or directly accessed
      if (this.id === val) {
        this.toggleFaq();
      }
    },
  },
  created() {
    this.generateID();
  },
  methods: {
    toggleFaq() {
      this.isClosed = !this.isClosed;
    },
    generateID() {
      this.id = this.faq.question
        .replace(/\s/g, '-')
        .replace(/\?/g, '')
        .toLowerCase();
    },
  },
};
