
import { mapState } from 'vuex';
import GContainer from '../g-container';
import GHeader from '../g-header';

export default {
  name: 'g-header-searchbox',
  components: {
    GContainer,
    GHeader,
    SearchIcon: () => import('assets/svg/icons/icon-search.svg'),
  },
  props: {
    headerProps: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFocus: false,
      searchText: '',
    };
  },
  computed: {
    ...mapState({
      complianceStates: state => state.complianceStates,
    }),
    searchedComplainceStates() {
      const result = this.complianceStates.filter(state =>
        state.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
      if (!result.length) {
        return [{ title: 'Page coming soon' }];
      }
      return result;
    },
  },
  methods: {
    focus() {
      this.isFocus = true;
    },
    blur() {
      this.isFocus = false;
    },
  },
};
