
import GFaqItem from '../g-faq-item';
import scrollToAnchor from '~/utils/scrollToAnchor';

export default {
  name: 'g-faq-collection',
  components: {
    GFaqItem,
  },
  mixins: [scrollToAnchor],
  props: {
    faqs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      anchored: null,
      faqItemOpened: null,
    };
  },
};
