
export default {
  name: 'g-insights-header',
  components: {
    iconCalendar: () => import('assets/svg/icons/icon-filled-calendar.svg'),
    iconClock: () => import('assets/svg/icons/icon-filled-clock.svg'),
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tagColorMappings: {
        webinar: 'green',
        training: 'green',
        ebook: 'pink',
        guide: 'pink',
      },
    };
  },
};
