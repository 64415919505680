
import richtextMethod, {
  removeEmptyTags,
  richtextType,
} from '~/utils/richtext';
import storyblokDatasources from '~/mixins/storyblokDatasources';

const __default__ = {
  name: 'g-highlight',
  mixins: [storyblokDatasources],
  props: {
    initialDelay: {
      type: Number,
      default: 1000,
    },
    subsequentDelays: {
      type: Number,
      default: 1000,
    },
    skipAnimation: {
      type: Boolean,
      default: false,
    },
    highlightColor: {
      type: String,
      default: null,
    },
    highlightPadding: {
      type: String,
      default: null,
    },
    richtext: {
      type: richtextType,
      default: null,
    },
    fontSize: {
      type: String,
      default: null,
    },
    mobileFontSize: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      width: this.skipAnimation ? '100%' : '0%',
    };
  },
  computed: {
    color() {
      const themeHighlights = {
        white: 'yellow-40',
        yellow: 'yellow-40',
        peach: 'peach-20',
        azure: 'azure-40',
        pink: 'pink-60',
        purple: 'purple-60',
        enterprise: 'forest-60',
      };
      return this.highlightColor || themeHighlights[this.theme];
    },
    fontSizes() {
      if (!this.mobileFontSize) {
        return this.fontSize;
      }

      if (this.fontSize) {
        return `md:${this.fontSize} ${this.mobileFontSize}`;
      }

      return null;
    },
    richtextValue() {
      if (!this.richtext) {
        return null;
      }

      const richtextHTML = this.cleanRichtext(this.richtext);
      return this.interpolateStats(richtextHTML);
    },
  },
  mounted() {
    // nextTick ensures child components have rendered and all highlights are found
    this.$nextTick(() => this.triggerHighlightAnimations());
  },
  methods: {
    richtextMethod,
    cleanRichtext(richtext) {
      return removeEmptyTags(this.richtextMethod(richtext));
    },
    triggerHighlightAnimations() {
      if (this.skipAnimation) {
        return;
      }

      this.$refs['highlight']
        ?.querySelectorAll('.highlight, .underline, .double-underline, .circle')
        .forEach((el, index) => {
          window.setTimeout(() => {
            el.classList.add('active');
          }, index * this.subsequentDelays + this.initialDelay);
        });
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "575c68aa": (_vm.highlightPadding),
  "c95a80d0": (_vm.width)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__