
export default {
  name: 'g-dropdown',
  components: {
    iconTriangle: () => import('assets/svg/icons/icon-dropdown.svg'),
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    currentSelection: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      buttonText: this.placeholder,
      selectedValue: null,
      selectedText: null,
      isOpen: false,
    };
  },
  watch: {
    currentSelection: function (value) {
      this.updateSelected(value);
    },
  },
  mounted() {
    this.$on('item-selected', this.updateSelected);
  },
  methods: {
    dropdownButtonClicked() {
      if (this.isOpen) {
        this.closeMenu();
      } else {
        this.isOpen = true;
        this.buttonText = this.placeholder;
        setTimeout(() => {
          document.addEventListener('click', this.closeMenu, { once: true });
        }, 0);
      }
    },
    updateSelected(selectedItem) {
      this.selectedValue = selectedItem.value;
      this.selectedText = selectedItem.text;
      this.buttonText = this.selectedText;
      this.$emit('change', selectedItem);
    },
    closeMenu() {
      this.isOpen = false;
      this.buttonText = this.selectedText || this.placeholder;
    },
  },
};
